import styled from "styled-components";
import { Dispatch, SetStateAction } from "react";

const Container = styled.div`
  padding: 0.5rem;
  display: flex;
  margin-bottom: 3rem;
`;
const Item = styled.div`
  cursor: pointer;
  padding: 6px 30px;
  margin-right: 12px;
  font-size: 17px;
  border-radius: 100px;
  color: ${(props) => (props.active ? "#fff" : "#F87171")};
  background-color: ${(props) => (props.active ? "#F87171" : "#fff")};
`;

type DropSelectorProps = {
  currentView: string;
  setCurrentView: Dispatch<SetStateAction<string>>;
};

function DropSelector({ currentView, setCurrentView }: DropSelectorProps) {
  return (
    <div className="navigation-container">
      <Item
        active={currentView === "all"}
        className="navigation-item"
        onClick={() => setCurrentView("all")}
      >
        All
      </Item>
      <Item
        active={currentView === "live"}
        className="navigation-item"
        onClick={() => setCurrentView("live")}
      >
        Live drops
      </Item>
      <Item
        active={currentView === "soon"}
        className="navigation-item"
        onClick={() => setCurrentView("soon")}
      >
        soon
      </Item>
      <Item
        active={currentView === "previous"}
        className="navigation-item"
        onClick={() => setCurrentView("previous")}
      >
        Previous
      </Item>
    </div>
  );
}

export default DropSelector;
