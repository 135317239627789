import React, { useEffect, useRef, useState } from "react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { Link, useLocation } from "react-router-dom";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import CurrencyToggle from "./CurrencyToggle";
import styled from "styled-components";
import { RouteName } from "../constant/routeNames";
import config from "../skinconfig.json";

interface TopNavProps {
  showCurrencyToggle?: boolean;
}

const ROUTES = [
  { url: RouteName.home, name: "Drops" },
  { url: RouteName.auctionsView, name: "Auction" },
];

const TopNav: React.FC<TopNavProps> = ({ showCurrencyToggle = false }) => {
  const wallet = useAnchorWallet();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLLIElement>(null);

  const { pathname } = useLocation();

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <div
        className="bg-[#F3F2EC] w-full flex items-center justify-between px-10"
        style={{
          background: "#F3F2EC",
        }}
      >
        <Logo>
          <Link to={RouteName.home}>
            <img alt="" src="/bvdlogo.png" />
          </Link>
        </Logo>

        <Wallet>
          {wallet ? (
            <WalletMultiButton className="ring-0" />
          ) : (
            <WalletMultiButton className="ring-0" ></WalletMultiButton>
          )}
        </Wallet>
      </div>
    </>
  );
};

const HeaderBar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  font-size: 22px;
  background-color: #fff;
`;

const Wallet = styled.ul`
  flex: 0 0 auto;
  margin: 0;
  padding: 0;
`;

const Logo = styled.div`
  flex: 0 0 auto;
  margin-right: 10px;

  img {
    height: 100px;
  }
`;

const Menu = styled.ul`
  list-style: none;
  display: inline-flex;
  flex: 1 0 auto;
  margin-bottom: 0;

  > .active {
    border-bottom: 4px solid var(--title-text-color);
  }

  > .active-submenu {
    background-color: rgba(255, 255, 255, 0.08);
  }

  > li {
    margin: 0 5px;
    padding: 3px;

    a {
      color: var(--main-text-color);
      list-style-image: none;
      list-style-position: outside;
      list-style-type: none;
      outline: none;
      text-decoration: none;
      text-size-adjust: 100%;
      touch-action: manipulation;
      transition: color 0.3s;

      img {
        max-height: 26px;
      }
    }

    &:hover,
    &:active {
      border-bottom: 4px solid var(--title-text-color);
    }

    &:hover > a {
      color: rgb(131, 146, 161);
    }
  }
`;

export default TopNav;
