import { useEffect, useMemo, useState } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { CandyShopDataValidator } from "@liqnft/candy-shop";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolflareWebWallet,
  getSolletWallet,
  getSolletExtensionWallet,
  getSolongWallet,
  getLedgerWallet,
  getSafePalWallet,
} from "@solana/wallet-adapter-wallets";

import TopNav from "./components/TopNav";
import { CurrencyProvider } from "./components/Currency";

import { RouteName } from "./constant/routeNames";
import { NETWORK, RPC_HOST } from "./utils/candy-shop";
import CandyMachineDrop from "./views/CandyMachineDrop";
import AuctionsView from "./views/AuctionsView";
import Footer from "./components/Footer";
import { MetaplexProvider } from "./providers/MetaplexProvider";

require("@solana/wallet-adapter-react-ui/styles.css");

const theme = createTheme({
  palette: {
    type: "dark",
  },
  overrides: {
    MuiButtonBase: {
      root: {
        justifyContent: "flex-start",
      },
    },
    MuiButton: {
      root: {
        textTransform: undefined,
        padding: "12px 16px",
      },
      startIcon: {
        marginRight: 8,
      },
      endIcon: {
        marginLeft: 8,
      },
    },
  },
});

// Used for a multi-currency shop
const currencyOptions = [
  {
    currencySymbol: "SOL",
    treasuryMint: "So11111111111111111111111111111111111111112",
    currencyDecimals: 9,
    priceDecimals: 3,
    volumeDecimals: 1,
    mainnetConnectionUrl: RPC_HOST,
  },
  {
    currencySymbol: "56p",
    treasuryMint: "56pdaHboK66cxRLkzkYVvFSAjfoNEETJUsrdmAYaTXMJ",
    currencyDecimals: 9,
    priceDecimals: 2,
    volumeDecimals: 1,
    mainnetConnectionUrl: RPC_HOST,
  },
];

const App = () => {
  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded.
  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getSolflareWebWallet(),
      getSolletWallet({ network: NETWORK }),
      getSolletExtensionWallet({ network: NETWORK }),
      getSolongWallet(),
      getLedgerWallet(),
      getSafePalWallet(),
    ],
    []
  );

  const [loading, setloading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 3500);
  }, []);

  if (loading) {
    return (
      <div
        className="absolute left-0 z-[9999] w-full flex items-center justify-center  h-screen "
        style={{ background: "#FCFCFF" }}
      >
        <img alt="loading" style={{ height: "350px" }} src={`./loading.gif`} />
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <ConnectionProvider endpoint={RPC_HOST!}>
        <WalletProvider wallets={wallets} autoConnect={true}>
          <CurrencyProvider currencyOptions={currencyOptions}>
            <WalletModalProvider>
              <CandyShopDataValidator>
                <MetaplexProvider>
                  <main>
                    <MainContainer>
                      <div>
                        <Routes>
                          <Route
                            path={RouteName.home}
                            element={
                              <>
                                <TopNav />
                                <CandyMachineDrop />
                              </>
                            }
                          />
                          <Route
                            path={RouteName.auctionsView}
                            element={
                              <>
                                <TopNav />
                                <AuctionsView />
                              </>
                            }
                          />
                        </Routes>
                      </div>
                    </MainContainer>
                  </main>
                </MetaplexProvider>
              </CandyShopDataValidator>
            </WalletModalProvider>
          </CurrencyProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ThemeProvider>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  text-align: center;
`;

export default App;
