import styled from "styled-components";
import HelpIcon from "@material-ui/icons/Help";
import { Popper } from "@material-ui/core";
import { useRef, useState } from "react";
import TraitBreakDown, { MagicEdenLink } from "./TraitBreakDown";
import { PublicKey } from "@solana/web3.js";
import BreakDownModal from "./BreakDownModal";

const Container = styled.div`
  font-size: 22px;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 2rem;
  font-size: 22px;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

const AlignCenter = styled.div`
  display: flex;
  align-items: center;
`;

type TraitDetailsProps = {
  name: string;
  price: number | undefined;
  solFee?: number;
  tokenName: string;
  breakdown: {} | undefined;
  magicEdenId: string | undefined;
  candyMachineId: PublicKey | undefined;
};
function TraitDetails({
  name = "trait #1",
  price = 1,
  solFee,
  tokenName,
  breakdown,
  magicEdenId,
  candyMachineId,
}: TraitDetailsProps) {
  const [openPopOver, setOpenPopOver] = useState(false);
  const ref = useRef();
  return (
    <Container>
      {openPopOver ? (
        <BreakDownModal>
          <TraitBreakDown
            breakdown={breakdown}
            magicEdenId={magicEdenId}
            candyMachineId={candyMachineId}
            closePopover={() => setOpenPopOver(false)}
          />
        </BreakDownModal>
      ) : null}
      <div className="flex items-center justify-between my-3 text-black">
        <div>
          <span>{name}</span>
        </div>
        <div>
          {name === "Rep Coin" ? (
            <>
              {" "}
              {price} {tokenName} + 0.1◎
            </>
          ) : name === "Commemorative Drop" || name === "Mysterious box" ? (
            <>200 REP</>
          ) : (
            <>
              {" "}
              {price} {tokenName}
            </>
          )}
        </div>
      </div>
    </Container>
  );
}

export default TraitDetails;
