import { Metaplex } from "@metaplex-foundation/js";
import { PublicKey } from "@solana/web3.js";
import { useMemo } from "react";
import useSWR from "swr";
import { useMetaplex } from "./useMetaplex";

const metaplexFetcher = async ([mx, candyMachineId]: [mx: Metaplex, candyMachineId: string]) => {
  console.log(candyMachineId)
  const asset = await mx.candyMachines().findByAddress({
    address: new PublicKey(candyMachineId),
  });

  return asset;
};

function useCandyMachine(candyMachineId: string) {
  const { metaplex } = useMetaplex();
  const { data, error } = useSWR([metaplex, candyMachineId], metaplexFetcher, {
    revalidateOnFocus: false, //todo check these settings
    revalidateOnReconnect: false,
  });

  const currentGuardGroup = useMemo(() => {
    if (!data || !data.candyGuard) return
    console.log(data)
    if (data.candyGuard.groups.length === 0) {
      return data.candyGuard
    }
    else {
      const reversed = data.candyGuard?.groups.slice().reverse()
      console.log(reversed)
      return reversed.find((group) => {
        if (!group.guards.startDate?.date) return false
        return Date.now() > (1000 * group.guards.startDate?.date.toNumber());
      });
    }
  }, [data])
  //todo: rewrite this to parse the guards
  return {
    loadingCandyMachine: !data && !error,
    candyMachine: data,
    currentGuardGroup,
    cmError: error,
  };
}

export default useCandyMachine;
