import config from "../skinconfig.json";
import styled from "styled-components";

const ModalContainer = styled.div`
  position: relative;
  z-index: 20;
`;

const Background = styled.div`
  position: fixed;
  background-color: ${config.colors.buttonBg};
  transition-property: opacity;
  opacity: 0.75;
  inset: 0;
`;

const ContentContainer = styled.div`
  position: fixed;
  z-index: 20;
  inset: 0;
  overflow-y: auto;
  > div {
    padding: 1rem;
    display: flex;
    min-height: 100%;
    align-items: center;
    justify-content: center;
  }
`;

function BreakDownModal({ children }) {
  return (
    <ModalContainer
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <Background />
      <ContentContainer>
        <div>{children}</div>
      </ContentContainer>
    </ModalContainer>
  );
}

export default BreakDownModal;
