// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// @ts-nocheck
import { useMetaplex } from "../../hooks/useMetaplex";
import { useEffect, useState, useMemo } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { CTAButton } from "../MintButton";
import { CircularProgress } from "@material-ui/core";
import {
  getMerkleProof,
  NftWithToken,
  CandyMachine,
  DefaultCandyGuardSettings,
} from "@metaplex-foundation/js";
import useCandyMachine from "../../hooks/useCandyMachine";
import ALLOW_LIST from "../../constant/allowList.json";
import config from "../../skinconfig.json";
import { toDate } from "../utils";

export default function MintButton({
  setAlertState,
  candyMachineId,
}: {
  setAlertState: any;
  candyMachineId: string;
}) {
  const { metaplex } = useMetaplex();
  const wallet = useWallet();

  const [nft, setNft] = useState<NftWithToken | null>(null);

  const [disableMint, setDisableMint] = useState(true);
  const [needsApproval, setNeedsApproval] = useState(false);
  const [merkleProof, setMerkleProof] = useState(null);
  const [isMinting, setIsMinting] = useState(false);

  let walletBalance;
  console.log(candyMachineId);
  const { candyMachine, currentGuardGroup } = useCandyMachine(candyMachineId);
  console.log(candyMachine);

  const addListener = async () => {
    if (!candyMachine || !candyMachine.candyGuard || !metaplex) return;
    // add a listener to monitor changes to the candy guard
    metaplex.connection.onAccountChange(candyMachine?.candyGuard?.address, () =>
      checkEligibility()
    );

    // add a listener to monitor changes to the user's wallet
    metaplex.connection.onAccountChange(metaplex.identity().publicKey, () =>
      checkEligibility()
    );

    // add a listener to reevaluate if the user is allowed to mint if startDate is reached
    const slot = await metaplex.connection.getSlot();
    const solanaTime = await metaplex.connection.getBlockTime(slot);
    const startDateGuard = candyMachine.candyGuard.guards.startDate;
    if (startDateGuard != null) {
      const candyStartDate = startDateGuard.date.toString(10);
      // @ts-ignore
      const refreshTime = candyStartDate - solanaTime.toString(10);
      if (refreshTime > 0) {
        setTimeout(() => checkEligibility(), refreshTime * 1000);
      }
    }

    // also reevaluate eligibility after endDate is reached
    const endDateGuard = candyMachine.candyGuard.guards.endDate;
    if (endDateGuard != null) {
      const candyEndDate = endDateGuard.date.toString(10);
      // @ts-ignore
      const refreshTime = solanaTime.toString(10) - candyEndDate;
      if (refreshTime > 0) {
        setTimeout(() => checkEligibility(), refreshTime * 1000);
      }
    }
  };

  const checkEligibility = async () => {
    //wallet not connected?
    if (!candyMachine || !wallet.connected || !wallet.publicKey || !metaplex) {
      console.log("first");
      setDisableMint(true);
      return;
    }

    // enough items available?
    if (
      // @ts-ignore
      candyMachine.itemsMinted.toString(10) -
        // @ts-ignore
        candyMachine.itemsAvailable.toString(10) >
      0
    ) {
      console.error("not enough items available");
      setDisableMint(true);
      return;
    }

    if (!candyMachine.candyGuard) return;
    // guard checks have to be done for the relevant guard group! Example is for the default groups defined in Part 1 of the CM guide
    const guard = currentGuardGroup?.guards;
    if (!guard) return;

    // Calculate current time based on Solana BlockTime which the on chain program is using - startTime and endTime guards will need that
    const slot = await metaplex.connection.getSlot();
    const solanaTime = await metaplex.connection.getBlockTime(slot);

    if (guard.startDate != null) {
      const candyStartDate = guard.startDate.date.toString(10);
      // @ts-ignore
      if (solanaTime < candyStartDate) {
        console.error("startDate: CM not live yet");
        setDisableMint(true);
        return;
      }
    }

    if (guard.endDate != null) {
      const candyEndDate = guard.endDate.date.toString(10);
      if (solanaTime > candyEndDate) {
        console.error("endDate: CM not live anymore");
        setDisableMint(true);
        return;
      }
    }

    if (guard.addressGate != null) {
      if (
        metaplex.identity().publicKey.toBase58() !=
        guard.addressGate.address.toBase58()
      ) {
        console.error("addressGate: You are not allowed to mint");
        setDisableMint(true);
        return;
      }
    }

    if (guard.mintLimit != null) {
      const mitLimitCounter = metaplex.candyMachines().pdas().mintLimitCounter({
        id: guard.mintLimit.id,
        user: metaplex.identity().publicKey,
        candyMachine: candyMachine.address,
        candyGuard: candyMachine.candyGuard.address,
      });
      //Read Data from chain
      const mintedAmountBuffer = await metaplex.connection.getAccountInfo(
        mitLimitCounter,
        "processed"
      );
      let mintedAmount;
      if (mintedAmountBuffer != null) {
        mintedAmount = mintedAmountBuffer.data.readUintLE(0, 1);
      }
      if (mintedAmount != null && mintedAmount >= guard.mintLimit.limit) {
        console.error("mintLimit: mintLimit reached!");
        setDisableMint(true);
        return;
      }
    }

    if (guard.solPayment != null) {
      walletBalance = await metaplex.connection.getBalance(
        metaplex.identity().publicKey
      );

      const costInLamports = guard.solPayment.amount.basisPoints.toString(10);

      if (costInLamports > walletBalance) {
        console.error("solPayment: Not enough SOL!");
        setDisableMint(true);
        return;
      }
    }

    if (guard.freezeSolPayment != null) {
      walletBalance = await metaplex.connection.getBalance(
        metaplex.identity().publicKey
      );

      const costInLamports =
        guard.freezeSolPayment.amount.basisPoints.toString(10);

      if (costInLamports > walletBalance) {
        console.error("freezeSolPayment: Not enough SOL!");
        setDisableMint(true);
        return;
      }
    }

    if (guard.nftGate != null) {
      const ownedNfts = await metaplex
        .nfts()
        .findAllByOwner({ owner: metaplex.identity().publicKey });
      const nftsInCollection = ownedNfts.filter((obj) => {
        return (
          obj.collection?.address.toBase58() ===
            guard.nftGate.requiredCollection.toBase58() &&
          obj.collection?.verified === true
        );
      });
      if (nftsInCollection.length < 1) {
        console.error("nftGate: The user has no NFT to pay with!");
        setDisableMint(true);
        return;
      }
    }

    if (guard.nftBurn != null) {
      const ownedNfts = await metaplex
        .nfts()
        .findAllByOwner({ owner: metaplex.identity().publicKey });
      const nftsInCollection = ownedNfts.filter((obj) => {
        return (
          obj.collection?.address.toBase58() ===
            guard.nftBurn.requiredCollection.toBase58() &&
          obj.collection?.verified === true
        );
      });
      if (nftsInCollection.length < 1) {
        console.error("nftBurn: The user has no NFT to pay with!");
        setDisableMint(true);
        return;
      }
    }

    if (guard.nftPayment != null) {
      const ownedNfts = await metaplex
        .nfts()
        .findAllByOwner({ owner: metaplex.identity().publicKey });
      const nftsInCollection = ownedNfts.filter((obj) => {
        return (
          obj.collection?.address.toBase58() ===
            guard.nftPayment.requiredCollection.toBase58() &&
          obj.collection?.verified === true
        );
      });
      if (nftsInCollection.length < 1) {
        console.error("nftPayment: The user has no NFT to pay with!");
        setDisableMint(true);
        return;
      }
    }

    if (guard.redeemedAmount != null) {
      if (
        guard.redeemedAmount.maximum.toString(10) <=
        candyMachine.itemsMinted.toString(10)
      ) {
        console.error(
          "redeemedAmount: Too many NFTs have already been minted!"
        );
        setDisableMint(true);
        return;
      }
    }

    if (guard.tokenBurn != null) {
      const ata = await metaplex.tokens().pdas().associatedTokenAccount({
        mint: guard.tokenBurn.mint,
        owner: metaplex.identity().publicKey,
      });
      const balance = await metaplex.connection.getTokenAccountBalance(ata);
      if (balance < guard.tokenBurn.amount.basisPoints.toNumber()) {
        console.error("tokenBurn: Not enough SPL tokens to burn!");
        setDisableMint(true);
        return;
      }
    }

    if (guard.tokenGate != null) {
      const ata = await metaplex.tokens().pdas().associatedTokenAccount({
        mint: guard.tokenGate.mint,
        owner: metaplex.identity().publicKey,
      });
      const balance = await metaplex.connection.getTokenAccountBalance(ata);
      if (balance < guard.tokenGate.amount.basisPoints.toNumber()) {
        console.error("tokenGate: Not enough SPL tokens!");
        setDisableMint(true);
        return;
      }
    }

    if (guard.tokenPayment != null) {
      const ata = await metaplex.tokens().pdas().associatedTokenAccount({
        mint: guard.tokenPayment.mint,
        owner: metaplex.identity().publicKey,
      });
      const balance = await metaplex.connection.getTokenAccountBalance(ata);
      if (balance < guard.tokenPayment.amount.basisPoints.toNumber()) {
        console.error("tokenPayment: Not enough SPL tokens to pay!");
        setDisableMint(true);
        return;
      }
      if (guard.freezeTokenPayment != null) {
        const ata = await metaplex.tokens().pdas().associatedTokenAccount({
          mint: guard.freezeTokenPayment.mint,
          owner: metaplex.identity().publicKey,
        });
        const balance = await metaplex.connection.getTokenAccountBalance(ata);
        if (balance < guard.tokenPayment.amount.basisPoints.toNumber()) {
          console.error("freezeTokenPayment: Not enough SPL tokens to pay!");
          setDisableMint(true);
          return;
        }
      }
    }
    if (guard.allowList != null) {
      const mkProof = getMerkleProof(ALLOW_LIST, wallet.publicKey?.toBase58());
      if (mkProof.length === 0) {
        console.error("allowList: Not allowed to mint!");
        setDisableMint(true);
        return;
      } else {
        setMerkleProof(mkProof);
        const allowListPdaAddress = metaplex
          .candyMachines()
          .pdas()
          .merkleProof({
            merkleRoot: guard.allowList.merkleRoot,
            user: metaplex.identity().publicKey,

            candyGuard: candyMachine.candyGuard.address,
            candyMachine: candyMachine.address,
          });
        const allowListPda = await metaplex.connection.getAccountInfo(
          allowListPdaAddress,
          "processed"
        );
        console.log(allowListPda?.data);
        setNeedsApproval(allowListPda?.data ? false : true);
      }
    }

    //good to go! Allow them to mint
    setDisableMint(false);
  };

  // // show and do nothing if no wallet is connected
  // if (!wallet.connected) {
  //   return null;
  // }

  // // if it's the first time we are processing this function with a connected wallet we read the CM data and add Listeners
  // if (candyMachine === undefined) {
  //   (async () => {
  //     // read candy machine data to get the candy guards address
  //     await checkEligibility();
  //     // Add listeners to refresh CM data to reevaluate if minting is allowed after the candy guard updates or startDate is reached
  //     addListener();
  //   })();
  // }
  useEffect(() => {
    (async () => {
      // read candy machine data to get the candy guards address
      await checkEligibility();
      // Add listeners to refresh CM data to reevaluate if minting is allowed after the candy guard updates or startDate is reached
      addListener();
    })();
  }, [wallet.connected, candyMachine]);

  const onClick = async () => {
    // Here the actual mint happens. Depending on the guards that you are using you have to run some pre validation beforehand
    // Read more: https://docs.metaplex.com/programs/candy-machine/minting#minting-with-pre-validation
    setIsMinting(true);
    try {
      if (!metaplex || !candyMachine) throw new Error("Missing candy machine");
      const { nft } = await metaplex.candyMachines().mint({
        group: currentGuardGroup?.label,
        candyMachine,
        collectionUpdateAuthority: candyMachine.authorityAddress,
      });

      setNft(nft);
    } catch (e) {
      console.log(e);
      setAlertState({
        open: true,
        message: "Mint failed! Please try again!",
        severity: "error",
      });
    }
    setIsMinting(false);
  };

  const preApprove = async () => {
    try {
      if (!merkleProof || !metaplex || !candyMachine || !currentGuardGroup)
        throw new Error("Missing data");

      await metaplex.candyMachines().callGuardRoute({
        candyMachine,
        group: currentGuardGroup.label,
        guard: "allowList",
        settings: {
          path: "proof",
          merkleProof,
        },
      });
      setNeedsApproval(false);
    } catch (e) {
      setAlertState({
        open: true,
        message: "Pre-approval failed. Please try again.",
        severity: "error",
      });
    }
  };

  return (
    <>
      {/* nft && (
        <div className="absolute z-10 w-full border top-1/3 md:w-1/3 md:left-1/3 bg-bg border-actionYellow">
          <div className="flex flex-col gap-2 px-4 py-9">
            <div className="text-center">You minted: </div>
            <div className="flex justify-center">
              <img
                src={nft.json?.image || ""} //get fallback
                width={300}
                height={300}
                alt="The downloaded illustration of the provided NFT address."
              />
            </div>
            <h1 className="pb-5 text-center">{nft.name}</h1>
            { <Button
              onClick={() => setNft(null)}
              primary
              label="Close"
              size="large"
              pill
            /> }
          </div>
        </div>
      )*/}
      <CTAButton disabled={isMinting || disableMint} onClick={onClick}>
        <MintButtonContent
          candyMachine={candyMachine}
          isMinting={isMinting}
          isValidBalance={disableMint}
        />
      </CTAButton>
    </>
  );
}

const MintButtonContent = ({
  candyMachine,
  isMinting,
  isValidBalance,
}: {
  candyMachine: CandyMachine<DefaultCandyGuardSettings> | undefined;
  isMinting: boolean;
  isValidBalance: boolean;
}) => {
  const startDate = useMemo(
    () => toDate(candyMachine?.candyGuard?.guards?.startDate?.date),
    [candyMachine]
  );
  if (candyMachine?.itemsRemaining.toNumber() === 0) {
    return <span>SOLD OUT</span>;
  } else if (isMinting) {
    return (
      <CircularProgress
        size={"20px"}
        style={{ color: config.colors.secondary, marginTop: "5px" }}
      />
    );
    // } else if (
    //   candyMachine?.state.isPresale ||
    //   candyMachine?.state.isWhitelistOnly
    // ) {
    //   return <span>WHITELIST MINT</span>;
  } else if (!isValidBalance) {
    return <span>MINT</span>;
  } else if (startDate && new Date() < startDate) {
    return <span>NOT LIVE YET</span>;
  }

  return <span>MINT</span>;
};
