// import useCandyMachine from "../hooks/useCandyMachine";
import { Grid } from "@material-ui/core";
import { PublicKey } from "@solana/web3.js";
import { Dispatch, SetStateAction } from "react";
import useCandyMachine from "../hooks/useCandyMachine";
import {
  CandyMachinePlus,
  MintContainer,
  StatusTab,
  Thumbnail,
} from "./CandyMachine";
import MintButton from "./CMv3/MintButton";
import DropsRemaining from "./DropsRemaining";
import { MintCountdown } from "./MintCountdown";
import TraitDetails from "./TraitDetails";
import { AlertState, formatNumber, toDate } from "./utils";

const CandyMachineV3 = ({
  candyMachineId,
  candyMachineConfig,
  setAlertState,
}: {
  candyMachineId: string;
  candyMachineConfig: CandyMachinePlus;
  setAlertState: Dispatch<SetStateAction<AlertState>>;
}) => {
  const { itemName, thumbnail, tokenName, breakdown, magicEdenId, solFee } =
    candyMachineConfig;
  const { candyMachine, loadingCandyMachine, currentGuardGroup } =
    useCandyMachine(candyMachineId);
  console.log(currentGuardGroup);
  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
      <MintContainer
        // @ts-ignore
        loading={loadingCandyMachine}
        style={{
          position: "relative",
          transitionProperty: "filter, opacity",
          transitionDelay: "0.2s",
          ...(!candyMachine
            ? {
                opacity: 0.3,
                "-webkit-filter": "blur(10px);",
                filter: "blur(10px)",
              }
            : {}),
        }}
      >
        <StatusTab>
          {candyMachine ? (
            <MintCountdown
              key="goLive"
              date={toDate(currentGuardGroup?.guards.startDate?.date)}
              style={{ justifyContent: "flex-end" }}
              status={
                candyMachine.itemsRemaining.toNumber() === 0 ||
                (currentGuardGroup?.guards.endDate &&
                  Date.now() >
                    1000 * currentGuardGroup?.guards.endDate.date.toNumber())
                  ? "SOLD OUT"
                  : "LIVE"
              }
            />
          ) : null}
        </StatusTab>
        <div
          style={{
            padding: "0.5rem",
          }}
        >
          <Thumbnail src={thumbnail} />
          <TraitDetails
            name={itemName}
            price={candyMachine?.candyGuard?.guards.tokenBurn?.amount.basisPoints.toNumber()}
            solFee={candyMachineConfig.solFee}
            breakdown={breakdown}
            candyMachineId={new PublicKey(candyMachineId)}
            tokenName={tokenName}
            magicEdenId={magicEdenId}
          />
          <DropsRemaining
            remaining={
              candyMachine ? candyMachine.itemsRemaining.toNumber() : 0
            }
            total={
              candyMachine ? candyMachine?.itemsAvailable.toNumber() : 10000
            }
          />
          <MintButton
            candyMachineId={candyMachineId}
            setAlertState={setAlertState}
          />
        </div>
      </MintContainer>
    </Grid>
  );
};

export default CandyMachineV3;
