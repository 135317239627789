import styled from "styled-components";
import config from "../skinconfig.json";

const Container = styled.div({});

const ProgressContainer = styled.div({
  opacity: 0.7,
  height: ".5rem",
  position: "relative",
  border: "1px solid",
  borderImageSlice: "1",
  borderImageSource: `linear-gradient(to left, ${config.colors.gradientFrom}, ${config.colors.gradientTo})`,
});

const ProgressBar = styled.div({
  position: "absolute",
  height: "100%",
  background:
    "linear-gradient(90deg, #9747FF 0%, #F09D30 106.39%, #F87171 196.41%);",
  transitionProperty: "width",
  transitionDuration: "1s",
});

const Label = styled.span({
  color: "grey",
  fontSize: "20px",
});

const Fraction = styled.span({
  fontSize: "20px",
});

type DropsRemainingProps = {
  remaining: number | undefined;
  total: number | undefined;
};

function DropsRemaining({ remaining = 0, total = 1 }: DropsRemainingProps) {
  return (
    <div>
      <div className="progresscontainer">
        <ProgressBar
          style={{
            width: `${(100 * remaining) / total}%`,
          }}
        ></ProgressBar>
      </div>
      <div
        style={{
          textAlign: "end",
          paddingTop: "1rem",
          color: "black",
          fontWeight: "light",
        }}
      >
        <Fraction>
          {remaining}/{total}
        </Fraction>
      </div>
    </div>
  );
}

export default DropsRemaining;
