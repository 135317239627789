import { Auctions } from "@liqnft/candy-shop";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { candyShop } from "../utils/candy-shop";
import styled from "styled-components";
import config from "../skinconfig.json";

const AuctionsView: React.FC = () => {
  const wallet = useAnchorWallet();

  return (
    <div className="h-screen px-5 py-5 overflow-y-scroll bb">
      <div className=" w-full wow fadeInLeft rounded-md min-h-[600px] overflow-scroll">
        <div className="flex items-center gap-1 ml-2 text-sm font-semibold lg:ml-10 lg:text-xl">
          <a href="/">
          <div
            className={
              "py-2 -mb-2 bg-[#DBD7CB] px-7 cursor-pointer text-black border-l-2 border-r-2 border-t-2 border-black rounded-t-xl  bg-[#f3f2ecbc] text-center"
            }
            style={{
              backgroundColor: "#DBD7CB",
              paddingLeft: "28px",
              paddingRight: "28px",
              borderRadius: "10px 10px 0px 0px",
            }}
          >
            Live Drops
          </div>
          </a>
          <a href="/auctions">
          <div
            className={
              "py-3 px-7 text-black border-l-2 border-r-2 border-t-2 cursor-pointer border-black rounded-t-xl  bg-[#f3f2ecbc] text-center"
            }
            style={{
              backgroundColor: "#f3f2ecbc",
              paddingLeft: "28px",
              paddingRight: "28px",
              borderRadius: "10px 10px 0px 0px",
            }}
          >
            Auctions
          </div>
          </a>
        </div>
        <div
          className="p-2 lg:p-5 border-2 border-black rounded-xl bg-[#f3f2ecbc]"
          style={{
            backgroundColor: "#f3f2ecbc",
            borderRadius: "10px 10px 10px 10px",
          }}
        >
          <div className="my-5" style={{ fontSize: "2em" }}>
            <h1 className="text-left text-black covered">Auctions</h1>
          </div>
          <Auctions
            candyShop={candyShop}
            wallet={wallet}
            walletConnectComponent={<WalletMultiButton />}
          />
        </div>
      </div>
    </div>
  );
};

export default AuctionsView;

