import styled from "styled-components";
import config from "../../skinconfig.json";

export default styled.button`
  height: 40px;
  width: 100%;
  background-color: ${config.colors.buttonBg};
  border: 1px solid ${config.colors.buttonBorder};
  color: ${config.colors.buttonText};
  opacity: ${(props) => (!props.disabled ? 1 : 0.6)};
  font-size: 20px;
  cursor: ${(props) => (!props.disabled ? "pointer" : "not-allowed")};
  font-family: ${config.fontFamily.headers};
`;
