import { Metaplex } from "@metaplex-foundation/js";
import { createContext, useContext } from "react";

const DEFAULT_CONTEXT = {
  metaplex: null,
};

export const MetaplexContext =
  createContext<{ metaplex: Metaplex | null }>(DEFAULT_CONTEXT);

export function useMetaplex() {
  return useContext(MetaplexContext);
}
