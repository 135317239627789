import { clusterApiUrl, PublicKey } from "@solana/web3.js";
import { useMemo } from "react";
import styled from "styled-components";
import config from "../skinconfig.json";
import { NETWORK } from "../utils/candy-shop";
import StyledButton from "./styles/StyledButton";
import CloseIcon from "@material-ui/icons/Close";

const CloseContainer = styled.div`
  margin-bottom: 1rem;
  font-size: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BreakDownContainer = styled.div`
  padding: 1rem;
  width: 300px;
  text-align: start;
  border: 1px solid ${config.colors.buttonBorder};
  background-color: ${config.colors.primary};
  color: ${config.colors.buttonText};
`;

const BreakDownItem = styled.div`
  padding: 0.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BreakDownItemLabel = styled.div`
  color: ${config.colors.labelText};
  padding: 0.1rem;
`;

const BreakDownItemValue = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.a`
  width: 36px;
  height: 30px;
`;

const Icon = styled.img`
  width: 100%;
  padding-left: 0.5rem;
`;

export const MagicEdenLink = ({
  magicEdenId,
  traitName,
}: {
  magicEdenId: string;
  traitName: string | undefined;
}) => (
  <IconContainer
    href={
      `https://magiceden.io/marketplace/${magicEdenId}` +
      (traitName
        ? `?attributes=${encodeURIComponent(
            JSON.stringify({ Trait: [traitName] }) //TODO: update for BVD
          )}`
        : "")
    }
    target="_blank"
    rel="noopener noreferrer"
  >
    <Icon src="/magiceden.png" />
  </IconContainer>
);

const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
`;
const MoreButton = styled(StyledButton)``;

type TraitBreakDownProps = {
  breakdown: {} | undefined;
  magicEdenId: string | undefined;
  candyMachineId: PublicKey | undefined;
  closePopover: () => void;
};

function TraitBreakDown({
  breakdown,
  candyMachineId,
  magicEdenId,
  closePopover,
}: TraitBreakDownProps) {
  const percentages = useMemo(() => {
    if (breakdown) {
      const total = Object.values(breakdown).reduce(
        (part, total) => total + part,
        0
      );
      return Object.fromEntries(
        Object.entries(breakdown).map(([traitName, traitTotal]) => [
          traitName,
          ((100 * traitTotal) / total).toFixed(2) + "%",
        ])
      );
    }
  }, [breakdown]);

  return (
    <BreakDownContainer>
      <CloseContainer>
        <div>item distribution</div>
        <CloseIcon style={{ cursor: "pointer" }} onClick={closePopover} />
      </CloseContainer>
      {Object.keys(breakdown).map((traitName) => (
        <BreakDownItem>
          <BreakDownItemLabel>{traitName}</BreakDownItemLabel>
          <BreakDownItemValue>
            {percentages[traitName]}
            <MagicEdenLink magicEdenId={magicEdenId} traitName={traitName} />
          </BreakDownItemValue>
        </BreakDownItem>
      ))}
      {candyMachineId ? (
        <ButtonContainer>
          <MoreButton
            onClick={() => {
              window.open(
                `https://www.solaneyes.com/address/${candyMachineId}?cluster=${NETWORK}`,
                "_blank",
                "noopener,noreferrer"
              );
            }}
          >
            more details
          </MoreButton>
        </ButtonContainer>
      ) : null}
    </BreakDownContainer>
  );
}

export default TraitBreakDown;
