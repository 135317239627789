import { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { PublicKey } from "@solana/web3.js";
import CandyMachine from "../components/CandyMachine";
import { NETWORK, RPC_HOST } from "../utils/candy-shop";
import { Connection } from "@solana/web3.js";
import { DEFAULT_TIMEOUT } from "../components/connection";
import candyMachines from "../constant/candyMachines.json";
import Alert from "@material-ui/lab/Alert";
import { AlertState } from "../components/utils";
import { Grid, Snackbar } from "@material-ui/core";
import DropSelector from "../components/DropSelector";

import { useWallet } from "@solana/wallet-adapter-react";
import { getCandyMachineState } from "../components/candy-machine";
import { Link } from "react-router-dom";
import CandyMachineV3 from "../components/CandyMachineV3";

const DEFAULT_CM_LOOKUP = {
  all: [...candyMachines.map((cm, i) => i)],
  live: [],
  soon: [],
  previous: [],
};

const Drop: React.FC = () => {
  const connection = new Connection(RPC_HOST);
  const wallet = useWallet();

  const [currentView, setCurrentView] = useState("all");
  const [CMS, setCMS] = useState([...candyMachines]);
  const [loaded, setLoaded] = useState(false);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const cmLookup = useMemo(() => {
    if (!loaded) return DEFAULT_CM_LOOKUP;
    return candyMachines.reduce(
      (lookup, item: { goLiveDate: string; candyMachineId: string }, i) => {
        // @ts-ignore
        lookup["all"].push(i);
        // @ts-ignore
        if (CMS[i].state && CMS[i].state.itemsRemaining === 0) {
          // @ts-ignore
          lookup["previous"].push(i);
        } else if (new Date(item.goLiveDate) > new Date()) {
          // @ts-ignore
          lookup["soon"].push(i);
        } else {
          // @ts-ignore
          lookup["live"].push(i);
        }
        return lookup;
      },
      {
        all: [],
        live: [],
        soon: [],
        previous: [],
      }
    );
  }, [CMS, loaded]);

  // @ts-ignore
  useEffect(async () => {
    if (!loaded) {
      try {
        const cmV2promises: Promise<CandyMachineAccount>[] = [];
        const cmV2Indices = {};
        const cmV3s = [];

        let j = 0;
        candyMachines.forEach((cm, i) => {
          if (cm.version === "v3") {
            cmV3s.push(cm);
          } else {
            // @ts-ignore
            cmV2Indices[cm.candyMachineId] = j;
            cmV2promises.push(
              getCandyMachineState(
                // @ts-ignore
                wallet,
                new PublicKey(cm.candyMachineId),
                connection
              )
            );
            j++;
          }
        });

        const cmv2Full = await Promise.all(cmV2promises);

        const allCMs = candyMachines.map((cm, i) => {
          if (cm.version === "v3") {
            return cm;
          } else {
            return {
              ...cm,
              // @ts-ignore
              ...cmv2Full[cmV2Indices[cm.candyMachineId]],
            };
          }
        });
        setCMS(allCMs);
        setLoaded(true);
      } catch (e) {
        console.log(e);
        setLoaded(false);
        setAlertState({
          open: true,
          message: "Failed to load drops",
          severity: "error",
        });
      }
    }
  }, []);

  if (!wallet.publicKey) {
    return (
      <div
        className="flex items-start justify-center min-h-screen pt-20 overflow-y-scroll text-black bb"
        style={{ fontSize: "3em" }}
      >
        connect your wallet to mint
      </div>
    );
  }

  return (
    <div className="h-screen px-2 py-5 overflow-y-scroll md:px-5 lg:px-10 bb">
      <div className=" w-full wow fadeInLeft rounded-md min-h-[600px] overflow-scroll">
        <div className="flex items-center gap-1 ml-2 text-sm font-semibold lg:ml-10 lg:text-xl">
          <a href="/">
            <div
              className={
                "py-3 px-7 text-black border-l-2 border-r-2 border-t-2 cursor-pointer border-black rounded-t-xl  bg-[#f3f2ecbc] text-center"
              }
              style={{
                backgroundColor: "#f3f2ecbc",
                paddingLeft: "28px",
                paddingRight: "28px",
                borderRadius: "10px 10px 0px 0px",
              }}
            >
              Live Drops
            </div>
          </a>
          <a href="/auctions">
            <div
              className={
                "py-2 -mb-2 bg-[#DBD7CB] px-7 cursor-pointer text-black border-l-2 border-r-2 border-t-2 border-black rounded-t-xl  bg-[#f3f2ecbc] text-center"
              }
              style={{
                backgroundColor: "#DBD7CB",
                paddingLeft: "28px",
                paddingRight: "28px",
                borderRadius: "10px 10px 0px 0px",
              }}
            >
              Auctions
            </div>
          </a>
        </div>
        <div
          className="p-2 lg:p-5 border-2 border-black rounded-xl bg-[#f3f2ecbc]"
          style={{
            backgroundColor: "#f3f2ecbc",
            borderRadius: "10px 10px 10px 10px",
          }}
        >
          <div className="my-5">
            <h1 className="text-4xl text-left text-black covered">Market</h1>
          </div>
          <DropSelector
            currentView={currentView}
            setCurrentView={setCurrentView}
          />
          <Grid container spacing={4}>
            {
              // @ts-ignore
              cmLookup[currentView].map((i) =>
                CMS[i].version && CMS[i].version === "v3" ? (
                  <CandyMachineV3
                    key={CMS[i].candyMachineId}
                    // @ts-ignore
                    candyMachineConfig={CMS[i]}
                    candyMachineId={CMS[i].candyMachineId}
                    setAlertState={setAlertState}
                  />
                ) : (
                  <CandyMachine
                    key={CMS[i].candyMachineId}
                    // @ts-ignore
                    initialCandyMachine={CMS[i]}
                    setAlertState={setAlertState}
                    candyMachineId={new PublicKey(CMS[i].candyMachineId)}
                    connection={connection}
                    txTimeout={DEFAULT_TIMEOUT}
                    rpcHost={RPC_HOST}
                    network={NETWORK}
                  />
                )
              )
            }
          </Grid>
          <Snackbar
            open={alertState.open}
            autoHideDuration={
              alertState.hideDuration === undefined
                ? 6000
                : alertState.hideDuration
            }
            onClose={() => setAlertState({ ...alertState, open: false })}
          >
            <Alert
              onClose={() => setAlertState({ ...alertState, open: false })}
              severity={alertState.severity}
            >
              {alertState.message}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </div>
  );
};

export default Drop;
const DesContainer = styled.div`
  width: 100%;
  padding: 1rem;
  padding-bottom: 5rem;

  @media only screen and (min-width: 768px) {
    padding: 2rem;
  }

  p > a {
    color: #fff;
    text-decoration: underline;
  }
`;
